window.onCompleted = function(event) {
  let form = document.querySelector('form[data-behavior="recaptcha"]');
  form.submit();
}

window.onloadCallback = function(event) {
  let el = document.querySelector('.g-recaptcha');
  let options = { "callback": window.onCompleted };

  grecaptcha.render(el, options)
}

document.addEventListener("turbo:load", function() {
  let form = document.querySelector('form[data-behavior="recaptcha"]');

  if (form) {
    let onSubmit = function(event) {
      let response = grecaptcha.getResponse();

      if (!response) {
        event.preventDefault();
        grecaptcha.execute();
      }
    }

    form.addEventListener("submit", onSubmit);
  }

  let passwordConfirmation = document.querySelector('input[name="user[password_confirmation]"');

  if (passwordConfirmation) {
    let validatePasswordConfirmation = function(event) {
      let password = document.querySelector('input[name="user[password]"');
      let passwordConfirmation = event.target;
      let customValidity = '';

      if (password.value !== passwordConfirmation.value) {
        customValidity = 'Passwords must match.';
      }

      passwordConfirmation.setCustomValidity(customValidity);
      passwordConfirmation.reportValidity();
    }

    passwordConfirmation.oninput = validatePasswordConfirmation;
  }
});
